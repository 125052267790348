/* You can add global styles to this file, and also import other style files */

@layer tailwind-base, primeng;

@import "tailwindcss/base" layer(tailwind-base);

@tailwind components;
@tailwind utilities;

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import "@angular/cdk/overlay-prebuilt.css";
@import "~@natural-apptitude/coreo-mapbox/dist/index.css";
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

@font-face {
  font-family: "Core Sans C";
  src:
    url("/assets/fonts/coresansc-35light.woff") format("woff"),
    url("/assets/fonts/coresansc-35light.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Core Sans C";
  src:
    url("/assets/fonts/coresansc-45regular.woff") format("woff"),
    url("/assets/fonts/coresansc-45regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Core Sans C";
  src:
    url("/assets/fonts/coresansc-65bold.woff") format("woff"),
    url("/assets/fonts/coresansc-65bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@import "./theme/tables.scss";

.geocoder-container {
  @apply absolute top-2.5 left-12 z-[10];

  .mapboxgl-ctrl-geocoder--input {
    font-family: "Core Sans C";
    padding: 6px 30px !important;
    @apply h-[30px] text-xs hover:shadow-[inset_0_0_0_1px] hover:shadow-[#D3DBE3]
    focus:shadow-[inset_0_0_0_1px] focus:shadow-[#0069DF] focus:outline-none rounded;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    @apply top-[5px];
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    @apply m-0;
  }
}

.panel {
  @apply bg-white rounded-lg border p-4;
  border-color: #c4cdd7;

  &-light {
    background-color: #f2f8fe;
  }

  &-no-padding {
    @apply p-0;
  }
}

.disabled {
  @apply pointer-events-none opacity-30;
}

.button-blue {
  @apply bg-[#0069DF] rounded-md text-white text-center cursor-pointer font-semibold
  hover:bg-[#005ECB] active:bg-[#004B9B];

  &.disabled,
  &[disabled] {
    @apply pointer-events-none opacity-30;
  }

  &.outline {
    @apply bg-white rounded-md text-[#0069DF];
  }

  &.rounded-full {
    border-radius: 9999px;
  }
}

.button-danger {
  @apply bg-danger rounded-md text-white text-center cursor-pointer font-semibold;

  &.disabled,
  &[disabled] {
    @apply pointer-events-none opacity-30;
  }

  &.outline {
    @apply bg-white rounded-md text-danger;
  }

  &.rounded-full {
    border-radius: 9999px;
  }
}

.button-white {
  @apply bg-white rounded-md text-[#0069DF] text-center cursor-pointer font-semibold
  shadow-[inset_0_0_0_1px] shadow-[#0069DF] hover:bg-[#F2F8FE] active:bg-[#DFEBFF];

  &.disabled,
  &[disabled] {
    @apply pointer-events-none opacity-30;
  }
}

.button-sm {
  @apply text-xs font-semibold h-8 px-3;
}

.mapboxgl-ctrl-switch {
  background: #fff;
  height: 29px;
  width: 29px;
  border-radius: 4px;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  &.streets {
    background: url(/assets/satellite.png);
  }
  &.satellite {
    background: url(/assets/streets.png);
  }
}

.cdk-dialog-container {
  outline: none !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

app-project-map {
  .mapboxgl-ctrl-bottom-left {
    bottom: 44px;
  }
}

.project-map {
  .mapboxgl-ctrl-top-left {
    display: flex;

    .mapboxgl-ctrl-group {
      height: 29px;
    }
  }

  .mapboxgl-ctrl-top-right,
  .mapboxgl-ctrl-bottom-right {
    right: 40px;
  }
}

.project-map-popup {
  .mapboxgl-popup-content {
    @apply rounded-lg text-[#444] p-5 pointer-events-none;
    box-shadow: 0px 4px 14px 0px rgba(14, 15, 17, 0.15);
  }
}

app-project-export-map.printing {
  .mapboxgl-ctrl-map-settings {
    display: none !important;
  }
}

.boxdraw {
  background: rgba(0, 105, 223, 0.1);
  border: 2px solid #3887be;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.project-container {
  @apply w-full grid;

  height: calc(100% - 55px);

  grid-template-rows: 1fr 1fr;

  grid-template-columns: 100vw;

  transition: all ease-in-out 0.5s;
}

app-project-baseline-map,
app-project-pre-survey-map {
  grid-row: 1;
  grid-column: 1 / span 1;
}

app-project-baseline-table,
app-project-pre-survey-table,
app-project-empty,
app-project-species-feature-notes-table {
  @apply w-full h-full block overflow-y-auto pr-4 bg-white;
  grid-column: 1;
  grid-row: 2;
}

app-project-sidebar {
  grid-column: 2;
  grid-row: 1 / span 2;
}

app-project-map .mapboxgl-ctrl-bottom-right {
  bottom: 46px;
}

.open .project-container {
  grid-template-columns: calc(100vw - 328px);
}

app-project-baseline.map-max,
app-project-pre-survey.map-max {
  .project-container {
    grid-template-rows: 20fr minmax(98px, 1fr);
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  @apply bg-primary;
}

ul.dropdown {
  @apply flex flex-col gap-2 p-2 bg-white rounded-lg text-base text-grey-120 drop-shadow-lg max-w-lg max-h-[90vh] overflow-y-auto;

  li {
    @apply flex items-center bg-white rounded-md p-2.5 hover:bg-secondary-lighter active:bg-secondary cursor-pointer;

    svg-icon {
      @apply text-grey-40 h-5 w-5 mr-6;
    }
  }
}

.segment-container {
  @apply border-b border-[#D9D9D9] flex;
}

.segment {
  @apply font-semibold text-sm text-[#878F97] border-b-4 border-transparent text-center p-3 cursor-pointer flex-1 hover:text-primary;
}
.segment.active {
  @apply text-primary border-primary;
}

.p-tooltip {
  @apply text-sm;
}

.cdk-overlay-pane:not(.loader) {
  .cdk-dialog-container {
    @apply p-5 bg-white rounded-lg;

    .header {
      @apply h-12 pb-5 flex justify-between items-center text-[22px] font-semibold border-b border-[#D9D9D9];
    }
  }
}

.cdk-virtual-scroll-content-wrapper {
  @apply min-h-full;
}

app-project-baseline-table .p-dropdown-label {
  font-size: 12px !important;
  color: rgb(107, 114, 128) !important;
}

app-project-baseline-table .p-dropdown {
  @apply w-full;
}

app-project-export-map-settings .p-dropdown-label {
  font-size: 14px !important;
}


input[type="color"]::-webkit-color-swatch-wrapper {
  @apply p-0;
}

input[type="color"]::-webkit-color-swatch {
  @apply p-0 border-0 border-r border-[#D3DBE3];
}

p-orderlist {
  .p-orderlist-list {
    min-height: 0;
  }

  .p-orderlist-controls {
    display: none;
  }

  .p-orderlist-list-container {
    border: none;

    ul {
      padding: 0;
    }

    li {
      padding: 16px 20px;
    }

    li.p-focus {
      background: inherit;
    }

    li:hover {
      background: #f2f8fe;
    }
  }
}

.p-tree {
  border: 0;
  padding: 0;

  .p-treenode {
    padding: 0;
  }

  .p-treenode-selectable {
    position: relative;
    padding: 6px 0;
    box-shadow: none;

    .p-tree-toggler {
      position: absolute;
      right: 0;
      margin: 0;
    }

    .p-treenode-label {
      color: #555c63;
      font-size: 14px;
      font-weight: 400;
      padding-right: 30px;
    }

    .p-checkbox {
      margin-right: 10px;

      .p-checkbox-box {
        border: 1px solid #7d838b;
      }
    }
  }

  .p-treenode-selectable:hover {
    background-color: transparent;
  }

  .p-treenode-content.p-highlight {
    background-color: transparent;
    color: #444;
  }
}

// .project-export-map-map .mapboxgl-ctrl-attrib-inner {
//   height: 20px;
//   overflow: hidden;
//   line-height: 20px;
//   color: blue;
//   border: 1px solid red;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: row;
//   overflow: hidden;
//   padding: 0;

//   a{
//     // line-height: 12px;
//     // height: 20px;
//     padding: 0;
//     color: blue;
//     overflow: hidden;
//     display: inline-block !important;
//     vertical-align: baseline !important;
//   }
// }
